import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import * as PR from "../../prime-react/index";
import { chatIcon, filterIcon } from "../../assets/images/index";
import AppliedFilter from './AppliedFilter';
import SearchFilter from './SearchFilter';
import { getAlertConversation, updateAlertConversation, createAlertConversation, getAlertResponses, updateAlertLasttRead, getAlertDetails, getFileDetails } from '../../services/api';
import { useDispatch, useSelector } from 'react-redux';
import { RAW_FILTER_DATA } from '../../shared/common';
import { chatCount, chatFileTypesConfig, cleanData, DateFormat, dateTimeFormat, fileUploadLimit, formatTime, getPlainText, getSportIcon, rowsPerPage, textEditorHeader } from '../../utils/reuse';
import { authActions } from '../../store/auth';
import ImageDisplay from '../ImageDisplay';
import AlertsSearchId from './AlertSearchId';
import { isEmpty, some } from 'lodash';
import { useToast } from '../../context/ToastContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const AlertsGrid = ({activeIndex, totalCount, offset, limit, sortValue, sortOrder, tabChangeHandler, getSelectedFilters, selectedFilters,handlePageChange, exportCSV, exportLoading, loading}) => {
    const dispatch = useDispatch();
    const { showToast } = useToast();
    const alerts = useSelector(state => state.auth.alerts);
    const sortKey = useRef({field:'createdOnRaw',value:!!sortValue ? 1 : -1})
    const pathname = window.location.pathname;
    const sessionData = useSelector(state => state.auth.sessionData);
    const groupPermissions = sessionData?.groupPermission;
    const headers = useMemo(() => {
        return { sessionid: sessionData.sessionId };
    }, [sessionData.sessionId]);
    const multipartheaders = useMemo(() => {
        return { sessionid: sessionData.sessionId, "Content-Type": "multipart/form-data" };
    }, [sessionData.sessionId]);
    const groupID = sessionData.groupID;
    const isMember = useSelector(state => state.auth.role) === 'member';
    const isAnalyst = useSelector(state => state.auth.role) === 'analyst';
    const isManagedMember = useSelector(state => state.auth.role) === 'managedmember';
    const [respondedVisible, setRespondedVisible] = useState(false);
    const [notRespondedVisible, setNotRespondedVisible] = useState(false);
    const [suspiciousVisible, setSuspiciousVisible] = useState(false);

    const [chatVisible, setChatVisible] = useState(false);
    const [filterDialogVisible, setFilterDialogVisible] = useState(false);
    const [filterVisible, setFilterVisible] = useState(true);
    const userName = sessionData.companyName;
    const [isAlertsUpdated, setIsAlertsUpdated] = useState(false);
    const [chatData, setChatData] = useState([]);
    const [chatParticipants, setChatParticipants] = useState([])
    const [chatInfo,setChatInfo] = useState({
        alertID:'',
        alertName:'',
        groupID:'',
        conversationID:'',
        legacyID: ''
    });
    const [chatLoading, setChatLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [isChatPopupOpen, setChatPopupOpen] = useState(false);

    const navigate = useNavigate();
    const gotoAlertDetails=(alertID)=>{
        getAlertDetails(alertID, headers, dispatch, ({result, error, data})=>{
            const alertDetailsData = {result, error, data};
            if(result==='SUCCESS' && (data && data?.alertID)) {
                localStorage.setItem('pageRefreshed', false);
                navigate("/alert-details/" + (data?.legacyID ? data.legacyID: data.alertID) , {state: {alertDetailsData}});
            } else {
                showToast("error", "Alert ID is not found");
            }
        });
    }
    const gotoAlertDetailsResponses=(legacyID, responseID)=>{
        setRespondedVisible(false)
        setSelectedRowData({})
        setRespondedQuery('')
        localStorage.setItem('pageRefreshed', false);
        setTimeout(() => {
            navigate("/alert-details/" + legacyID, {state:{responseID}});
        }, 500)
    }
    const setFilters = (data) => {
        getSelectedFilters(data)
        tabChangeHandler(activeIndex,data)
    }
    const filterHideShow = () => {
        setFilterVisible(!filterVisible);
    };

    const getChatConversation = (id, groupID) => {
        setChatLoading(true)
        getAlertConversation(id, groupID, headers, dispatch, response => {
            setChatPopupOpen(true)
            if(response.data?.messages) {
                const messages = response.data.messages.length > 0 ? response.data.messages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)) : [];
                setChatData(messages);
                setChatInfo(prev => ({
                    ...prev,
                    conversationID:response.data.conversationID
                }))
                setChatParticipants(response.data.participants);

                // to update chatRead only when readAt is null for last sender's message
                const lastSenderMessage = response.data.messages.filter(message => message.senderID !== sessionData.groupID).pop();
                if (lastSenderMessage && !lastSenderMessage.readAt) {
                    setIsAlertsUpdated(true);
                }
            } else {
                setChatData([])
            }
            setChatLoading(false);
        })
    }

    const updateChatRead = (chatID, formData) => {
        if (chatID) {
            updateAlertLasttRead(chatID, formData, multipartheaders, dispatch, response => {
                if(response.result === 'SUCCESS'){
                    const responseData = response.data;
                    changeActiveConversation(chatInfo?.alertID, responseData?.activeConversation);
                }
            })
        }
    }

    const currentConversationID = useMemo(() => chatInfo?.conversationID, [chatInfo]);
    useEffect(() => {
        if (currentConversationID && isAlertsUpdated) {
            // updateChatRead(currentConversationID, true); 
            const payload = {
                isRead: true,
                includeActiveConversation: true
            }
            const formData = new FormData();
            formData.append("data", JSON.stringify(payload));
            updateChatRead(currentConversationID, formData); 
            setIsAlertsUpdated(false);
        }
    // eslint-disable-next-line
    },[isAlertsUpdated])


    const getChatUserName = (senderId) => {
        const userName = chatParticipants.filter(user => user.ID=== senderId).map(user => user.name)
        return userName[0]
    }
    const getChatUserImage = (senderId) => {
        const userImage = chatParticipants.filter(user => user.ID === senderId)?.map(user => user.image);
        return userImage[0];
    }

    const setResponseCount = (id, responseCount) => {
        const alert = alerts.find(alert => alert.alertID === id)
        const updatedAlert = { ...alert, ...responseCount }
        const updatedAlerts = alerts.map(alert => alert.alertID === id ? updatedAlert:alert)

        dispatch(authActions.updateAlertsList(updatedAlerts))
    }

    const changeActiveConversation = (id, isActive) => {
        const alert = alerts.find(alert => alert.alertID === id)
        const updatedAlert = { ...alert, activeConversation: isActive };
        const updatedAlerts = alerts.map(alert => alert.alertID === id ? updatedAlert : alert);

        dispatch(authActions.updateAlertsList(updatedAlerts));
    }

    const [responsesPopupLoading, setResponsesPopupLoading] = useState(false)
    const getAlertResponsesHandler = (id , legacyID) => {
        setResponsesPopupLoading(true)
        getAlertResponses(id, headers, dispatch, response => {
            if (response.result === 'SUCCESS' && response.data) { 
                let data = {alertID: id, legacyID}
                const alertResponses = response.data?.sort((a, b) => a.respondingGroup.localeCompare(b.respondingGroup));
                
                const respondedInfo = alertResponses.filter(response => response.responseID)
                const notRespondedInfo = alertResponses.filter(response => !response.responseID)

                data["respondedInfo"] = respondedInfo
                data["respondedCount"] = respondedInfo.length

                const claimedSuspicious = respondedInfo.filter(response => response.responseType === "offered and suspicious")
                
                data["suspiciousInfo"] = claimedSuspicious
                data["suspiciousCount"] = claimedSuspicious.length
                
                data["notRespondedInfo"] = notRespondedInfo
                data["notRespondedCount"] = notRespondedInfo.length

                setResponseCount(id,{
                    responded: data["respondedCount"],
                    notResponded: data["notRespondedCount"],
                    suspicious: data["suspiciousCount"],
                })

                setSelectedRowData(data)
                setResponsesPopupLoading(false)
            }else{
                setResponsesPopupLoading(false)
            }
        })
    }

    const eventID = (rowData) => {
        return (
            <div className="flex align-items-center gap-3">
                <div className='sports-icon'>{getSportIcon(rowData?.sportID + 'b')}</div>
                <div className="event-column">
                    <p>{rowData.alertName}</p>
                    <span>ID: {rowData.legacyID}</span>
                </div>
            </div>
        );
    };

    const createdAtDateDisplay = (rowData) => {
        return (
            <div className="flex align-items-center gap-3">
                <div className="event-column">
                    <p>{DateFormat(rowData.createdAt)}</p>
                    <span>{formatTime(rowData.createdAt)}</span>
                </div>
            </div>
        );
    };
    const [rawFilterData] = useState(RAW_FILTER_DATA);

    const status = (rowData) => {
        const alertStatus = rawFilterData.statusFilter?.find(item => item.id === rowData.alertStatus)
        const caseStatus = rawFilterData.caseStatus?.find(item => item.id === rowData.caseStatus)

        const currentLabel = caseStatus?.name ?`${alertStatus?.name}: ${caseStatus?.name}`: alertStatus?.name
        const additionalStyle = alertStatus?.style || '';

        return (
            <div className={`status ${additionalStyle}`}>
                {currentLabel}
            </div>
        )
    };

    const alertTypeHandler = (row) => {
      return (
        <div>{rawFilterData.alertTypeFilter.find(item => item.id === row.alertType)?.name}</div>
      )
    };

    const [selectedRowData, setSelectedRowData] = useState({});
    const response = (rowData) => {
        const disableStyle = rowData.responded>0 ?'':'disableStyle'
        return (
            <>
                <div className="flex align-items-center gap-2">
                    <div className={`response ${disableStyle}`} onClick={() => {
                            if(rowData.responded>0){
                                setRespondedVisible(true)
                                getAlertResponsesHandler(rowData.alertID, rowData?.legacyID)
                            }
                        }}
                    >
                        <i className="pi pi-check"></i> {rowData.responded}
                    </div>
                    <div className={`response not-respond ${disableStyle}`} onClick={() => {
                            if(rowData.responded>0 && rowData.notResponded>0){
                                setNotRespondedVisible(true)
                                getAlertResponsesHandler(rowData.alertID, rowData?.legacyID)
                            }
                        }}
                    >
                        <i className="pi pi-times"></i> <span className='text-blur'>{rowData.notResponded}</span>
                    </div>
                    <div className={`response suspicious ${disableStyle}`} onClick={() => {
                            if(rowData.responded>0 && rowData.suspicious>0){
                                setSuspiciousVisible(true)
                                getAlertResponsesHandler(rowData.alertID, rowData?.legacyID)
                            }
                        }}
                    >
                        <i className="pi pi-info-circle"></i> <span className='text-blur'>{rowData.suspicious}</span>
                    </div>
                    {!isManagedMember && <div
                        className={`response chat
                            ${(isAnalyst && sessionData.groupID === rowData.createdByGroupID) ?  rowData?.activeConversation ? 'non-clickable' : 'disabled' : ''}
                            ${isMember && (!rowData.conversationExists) ? 'disabled' : 'clickable'}
                            ${rowData?.activeConversation ? 'active' : ''}
                        `}
                    >
                        <PR.Image src={chatIcon} alt="Chat ID" className="icon" onClick={() => {
                            setChatVisible(true)
                            // setChatData(rowData?.ibiaChats || [])

                            const id = isMember ? groupID : rowData.createdByGroupID
                            getChatConversation(rowData?.alertID, id)
                            setChatInfo(prev => ({
                                ...prev,
                                groupID:id,
                                alertID:rowData?.alertID,
                                alertName: rowData?.alertName,
                                legacyID: rowData?.legacyID
                            }))
                        }}/>
                    </div>}
                </div>
            </>
        );
    };
    const action = (rowData) => {
        return (
            <div className="flex flex-wrap">
                <PR.Button label="View Details" type='submit' severity="secondary" outlined className="view-details-button" onClick={() => gotoAlertDetails(rowData.alertID)} />
                {(rowData?.alertStatus === 'open' || rowData?.alertStatus === 'evaluating') && (rowData.responseRequired || activeIndex === 2) && <span className="response-span flex align-items-center gap-1"><i className="pi pi-info-circle"></i> Response required</span>}
            </div>
        );
    };


    const header = (
        <>
            <div className="status-filter-section flex gap-2">
                <AlertsSearchId headers={headers} goTo={gotoAlertDetails} disabledField={loading || exportLoading}/>
                <PR.Button className={cleanData(selectedFilters) ? 'search-filters-button search-filter-applied' : 'search-filters-button'} iconPos='left' outlined onClick={() => setFilterDialogVisible(true)} disabled={loading || exportLoading}>
                    <PR.Image src={filterIcon} alt="Filter Icon" className="icon" />
                    <span className="label">Search Filters</span>
                </PR.Button> 
                {((groupPermissions?.includes('export') && activeIndex === 0) || (isAnalyst && pathname === "/home")) && 
                <PR.Button className="search-filters-button export-icon" iconPos='left' outlined onClick={exportCSV} disabled={loading || exportLoading}>
                    <i className='pi pi-upload export-icon'></i>
                    <span className="label">Export</span>
                </PR.Button>}
            </div>
            {(some(selectedFilters, v =>!isEmpty(v))) &&
                <AppliedFilter selectedFilters={selectedFilters} setFilters={setFilters} filterVisible={filterVisible} filterHideShow={filterHideShow}/>
            }
        </>
    );
    const respondedFooterContent = (
        <div>
            <PR.Button 
                label="Close" 
                onClick={() => {
                    setRespondedVisible(false)
                    setSelectedRowData({})
                    setRespondedQuery('')
                }} 
                outlined 
                className="logout-btns" />
        </div>
    );

    const notRespondedFooterContent = (
        <div>
            <PR.Button 
                label="Close" 
                onClick={() => {
                    setNotRespondedVisible(false)
                    setSelectedRowData({})
                    setNotRespondedQuery('')
                }} 
                outlined 
                className="logout-btns" />
        </div>
    );

    const suspiciousFooterContent = (
        <div>
            <PR.Button 
                label="Close"
                onClick={() => {
                    setSuspiciousVisible(false)
                    setSelectedRowData({})
                    setSuspiciousQuery('')
                }} 
                outlined className="logout-btns" />
        </div>
    );

    const messagesEndRef = useRef(null);
    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    useEffect(() => {
        if (chatVisible) {
            setTimeout(scrollToBottom, 10);
        }
    }, [chatVisible, chatData]);

    const [respondedQuery, setRespondedQuery] = useState('');

    const filteredRespondedInfo = selectedRowData?.respondedInfo?.filter(company =>
        company.respondingGroup.toLowerCase().includes(respondedQuery.toLowerCase())
    );

    const [notRespondedQuery, setNotRespondedQuery] = useState('');

    const filteredNotRespondedInfo = selectedRowData?.notRespondedInfo?.filter(company =>
        company.respondingGroup.toLowerCase().includes(notRespondedQuery.toLowerCase())
    );

    const [suspiciousQuery, setSuspiciousQuery] = useState('');

    const filteredSuspiciousInfo = selectedRowData?.suspiciousInfo?.filter(company =>
        company.respondingGroup.toLowerCase().includes(suspiciousQuery.toLowerCase())
    );

    const showCompanyColumn = () => {
        return (
            (activeIndex!==3 && (pathname ==='/alerts') && (isMember || isManagedMember) ) ||
            (isAnalyst)
        )
    }

    const getReference = (count) => {
        return count > 1 ? 'members':'member'
    }
    const sortAlertType = (v) => {
        const value = sortKey.current.value===1?-1:1
        sortKey.current={field:'alertType', value}
        let arr = [...alerts]
        arr.sort((a,b)=>  v ===1 ? a.alertType.localeCompare(b.alertType) : b.alertType.localeCompare(a.alertType) )
        dispatch(authActions.updateAlertsList(arr));
    }

    const updateChat = (chatID, formData) => {
        setChatLoading(true)
        updateAlertConversation(chatID, formData, multipartheaders, dispatch, response => {
            getChatConversation(chatInfo.alertID, chatInfo.groupID)
            setChatLoading(false);
            formik.resetForm();
        })
    }

    const createChat = (alertID, groupID, formData) => {
        setChatLoading(true)
        createAlertConversation(alertID, groupID, formData, multipartheaders, dispatch, response => {
            getChatConversation(chatInfo.alertID, chatInfo.groupID)
            setChatLoading(false);
            formik.resetForm();
        })
    }
    
    const handleSubmit = (formValues) => {
        let obj = {};
        obj.content = formValues.content;
        const refinedObj = cleanData(obj);
        const formData = new FormData();
        if (refinedObj && Object.keys(refinedObj).length > 0) {
            formData.append('data', JSON.stringify(refinedObj)); 
        }
        if (formValues.attachments && formValues.attachments.length > 0) {
            formValues.attachments.forEach((file) => {
                formData.append('attachments', file);
            });
        }

        if(chatData?.length === 0) {
            createChat(chatInfo.alertID, chatInfo.groupID, formData)
        } else {
            updateChat(chatInfo.conversationID, formData)
        }
        setErrorMessage('');
    };

    const initialValues = {
        content: '',
        attachments: [],
    };

    const validationSchema = () => {
        return Yup.object().shape({
            attachments: Yup.array()
                .nullable()
                .test("fileSize", `Attachment must be less than ${fileUploadLimit.label}`, (value) => {
                    return !value || value.every(file => file.size <= fileUploadLimit.value);
                })
                .test("fileType", "Only PDF, DOC, DOCX, XLS, XLSX, CSV, PNG, JPG or JPEG files are allowed", (value) => {
                    return !value || value.every(file => chatFileTypesConfig.includes(file.type));
                }),
        });
    };       

    const formik = useFormik({
        initialValues,
        validateOnChange: true,
        validateOnBlur: true,
        validateOnMount: true,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
    });

    const fetchFileDetails = (key, fileName) => {
        const convertBlobToPDFAndDownload = async (pdfData, fileName) => {
            try {
                const url = URL.createObjectURL(pdfData);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error converting base64 to PDF:', error);
            }
        };

        getFileDetails(key, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                const fileData = response.data;
                convertBlobToPDFAndDownload(fileData, fileName);
            } else {
                const error = response?.error;
                showToast(error.severity, error?.errorMsg || error?.summary);
            }
        });
    };

    const handleDeleteFile = (index) => {
        const updatedAttachments = [...formik.values.attachments];
        updatedAttachments.splice(index, 1);
        formik.setFieldValue('attachments', updatedAttachments);
    };

    const chatFooterContent = (
    <div className='align-items-center justify-content-between'>
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <span className="ask-field">
                <PR.Editor
                    placeholder="Message to send..."
                    name="content"
                    value={formik.values.content}
                    onTextChange={(e) => {
                        const htmlValue = e.htmlValue;
                        if(getPlainText(htmlValue) === 0 && htmlValue !== null){
                            setErrorMessage('Enter valid text, only spaces are not allowed.');
                        } else if (getPlainText(htmlValue) > chatCount) {
                            formik.setFieldValue('content', htmlValue);
                            setErrorMessage(`Message should be ${chatCount} characters or less.`);
                        } else {
                            formik.setFieldValue('content', htmlValue);
                            setErrorMessage("");
                        }
                    }}
                    headerTemplate={textEditorHeader}
                    style={{ minHeight: "60px", maxHeight: "450px", overflowY: "auto" }}
                />
                <div className="align-items-start justify-content-between mt-2">
                    <div className="descriptioncharLimit">
                        Remaining Characters: {chatCount - (getPlainText(formik.values.content) || 0) > 0
                            ? chatCount - (getPlainText(formik.values.content) || 0)
                            : 0
                        }
                        <span style={{ textAlign: "left" }}>
                            {errorMessage && <div className="error-message">{errorMessage}</div>}
                        </span>
                    </div>
                </div>
                <div className="flex flex-column gap-2 mt-3">
                    <div className="card file-upload flex align-items-center">
                        <PR.FileUpload
                            auto
                            mode="advanced"
                            name="attachments"
                            accept=".pdf,.csv,.docx,.xlsx,.doc,.xls,.png,.jpeg,.jpg"
                            chooseLabel="Choose File"
                            uploadLabel="Upload"
                            emptyTemplate={null}
                            itemTemplate={null}
                            customUpload
                            onSelect={(event) => {
                                const selectedFiles = event.files; // Get the selected files
                                formik.setFieldTouched("attachments", true); // Mark field as touched
                                formik.setFieldValue("attachments", selectedFiles); // Update Formik's state

                            }}
                        />
                        <span className="ml-2"> 
                            <span className="block">(PDF, DOC, DOCX, XLS, XLSX, CSV, PNG, JPG or JPEG)</span>
                            File should be less than {fileUploadLimit.label}.
                        </span>

                    </div>
                    {formik.values.attachments && (
                        <div className="uploaded-file-container flex flex-column gap-2">
                            {formik.values.attachments?.map((attachment, index) => (
                                <div className="uploaded-files" key={index}>
                                    {attachment.name}
                                    <i className="pi pi-trash" onClick={() => handleDeleteFile(index)}/>
                                </div>
                            ))}
                        </div>
                    )}
                    {formik.errors.attachments && (formik.touched.attachments || formik.submitCount > 0) && (
                        <div className="error-message">
                            {Array.isArray(formik.errors.attachments) ? (
                                Array.from(new Set(formik.errors?.attachments)).map((error, index) => (
                                    <div key={index}>{error}</div>
                                ))
                            ) : (
                                <div>{formik.errors.attachments}</div>
                            )}
                        </div>
                    )}
                    <div className='text-right'>
                        <PR.Button label="Send Message"
                            outlined className="dialog-logout-btns send-message"
                            loading={chatLoading}
                            disabled={ !(formik.values.content?.trim() || formik.values.attachments?.length > 0) || errorMessage || chatLoading }
                            type='submit'
                        />
                    </div>    
                </div>
            </span>
        </form>
    </div>
        
    );

    return (
        <>
            <div className="grid grid-nogutter">
                <div className="card col-12 relative">
                    <PR.DataTable 
                        value={alerts} 
                        stripedRows 
                        className="data-table"
                        paginator
                        lazy
                        onPage={handlePageChange}
                        first={offset}
                        totalRecords={totalCount}
                        rows={limit}
                        rowsPerPageOptions={rowsPerPage}
                        header={header} 
                        tableStyle={{ minWidth: '50rem' }}
                        paginatorTemplate={{layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown', 'PageLinks': (options) => {
                            if(options.currentPage+2 >= options.page && options.currentPage-2 <= options.page )return options.element
                            else if (0 === options.page) return options.element
                            else if(3 >= options.page) return <>.</>
                            else if (options.totalPages-1 === options.page) return options.element
                            else if(options.totalPages-4 <= options.page) return <>.</>
                        } }}
                        pageLinkSize={Math.ceil(totalCount/limit)}
                        sortField={sortKey.current.field}
                        sortOrder={sortKey.current.value}
                        onSort={({sortField, sortOrder:v})=>{sortField==="createdOnRaw"&& sortOrder(); sortField === 'alertType' && sortAlertType(v) }}
                    >
                        <PR.Column body={eventID} header="Event" style={{width:"30%"}}></PR.Column>
                        <PR.Column 
                            body={createdAtDateDisplay}
                            header="Created" 
                            sortable
                            sortField="createdOnRaw"
                            style={{width:"10%"}}
                        ></PR.Column>
                        <PR.Column body={alertTypeHandler} header="Type" sortable sortField="alertType"></PR.Column>
                        {showCompanyColumn() && <PR.Column field="createdByGroupName" header="Company" className='capitalize'></PR.Column>}
                        <PR.Column body={status} header="Status"></PR.Column>
                        <PR.Column body={response} header="Response"></PR.Column>
                        <PR.Column body={action} header="Action" className="last-column"></PR.Column>
                    </PR.DataTable>
                </div>
            </div>
            {/* Members Responded Dialog */}
            {responsesPopupLoading && respondedVisible?(
                <div className="loading chat-loading">
                    <i className="pi pi-spin pi-spinner"></i>
                </div>
            ):(<PR.Dialog visible={respondedVisible} footer={respondedFooterContent} className="logout-dialog response-dialog" onHide={() => setRespondedVisible(false)} closeOnEscape="true" blockScroll="true">
                {/****  Members Responded ****/}
                <div className="dialog-header">
                    <div className='flex align-items-center justify-content-between mb-5'>
                        <h2>{selectedRowData?.respondedCount} {getReference(selectedRowData?.respondedCount)} responded</h2>
                        <span className='suspicious-span'>{selectedRowData?.suspiciousCount} claimed Suspicious</span>
                    </div>
                    <span className="p-input-icon-left search-field">
                        <i className="pi pi-search" />
                        <PR.InputText
                            placeholder="Search"
                            value={respondedQuery} 
                            onChange={(e) => setRespondedQuery(e.target.value)} 
                        />
                    </span>
                </div>
                <div className="dialog-body-section">

                    {filteredRespondedInfo?.map((company, index) => {
                        return (
                            <div key={index} className="flex align-items-center member-response-list justify-content-between">
                                <div className='left'>
                                    <ul className='flex list-none align-items-center gap-2'>
                                        <li className='flex align-items-center gap-2'>
                                            <ImageDisplay profileImage={company?.groupImage} type="group"/> 
                                            <span title={company.respondingGroup}>{company.respondingGroup?.length > 20 ? company.respondingGroup?.slice(0, 20)+'..': company.respondingGroup}</span>
                                        </li>
                                        <li>|</li>
                                        <li>{dateTimeFormat(company.createdAt)}</li>
                                    </ul>
                                </div>
                                <div className='right flex align-items-center'>
                                    <span className={(company.responseType === 'offered and suspicious')?'suspicious':null}>{company.responseType}</span> <i className="pi pi-chevron-right" onClick={() => gotoAlertDetailsResponses(selectedRowData?.legacyID,company?.responseID )}></i>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </PR.Dialog>)}

            {/* Members Not Responded Dialog */}
            {responsesPopupLoading && notRespondedVisible?(
                <div className="loading chat-loading">
                    <i className="pi pi-spin pi-spinner"></i>
                </div>               
            ):(<PR.Dialog visible={notRespondedVisible} footer={notRespondedFooterContent} className="logout-dialog response-dialog" onHide={() => setNotRespondedVisible(false)} closeOnEscape="true" blockScroll="true">

                {/* Members Not Responded  */}
                <div className="dialog-header">
                    <div className='flex align-items-center justify-content-between mb-5'>
                        <h2>{selectedRowData?.notRespondedCount} {getReference(selectedRowData?.notRespondedCount)} not responded</h2>
                    </div>
                    <span className="p-input-icon-left search-field">
                        <i className="pi pi-search" />
                        <PR.InputText 
                            placeholder="Search"
                            value={notRespondedQuery} 
                            onChange={(e) => setNotRespondedQuery(e.target.value)} 
                        />
                    </span>
                </div>
                <div className="dialog-body-section">
                    {filteredNotRespondedInfo?.map((company, index) => {
                        return (
                            <div key={index} className='not-responded-list'>
                                <ImageDisplay profileImage={company?.groupImage} type="group"/> <span>{company.respondingGroup}</span>
                            </div>
                        )
                    })}
                </div> 
            </PR.Dialog>)}

            {/* Members Claimed Suspicious Dialog */}
            {responsesPopupLoading && suspiciousVisible?(
                <div className="loading chat-loading">
                    <i className="pi pi-spin pi-spinner"></i>
                </div>
            ):(<PR.Dialog visible={suspiciousVisible} footer={suspiciousFooterContent} onHide={() => setSuspiciousVisible(false)} className="logout-dialog response-dialog" closeOnEscape="true" blockScroll="true">
                {/* Members Claimed Suspicious  */}
                <div className="dialog-header">
                    <div className='flex align-items-center justify-content-between mb-5'>
                        <h2>{selectedRowData?.suspiciousCount} {getReference(selectedRowData?.suspiciousCount)} claimed Suspicious</h2>
                    </div>
                    <span className="p-input-icon-left search-field">
                        <i className="pi pi-search" />
                        <PR.InputText 
                            placeholder="Search"
                            value={suspiciousQuery}
                            onChange={(e) => setSuspiciousQuery(e.target.value)} 
                        />
                    </span>
                </div>
                <div className="dialog-body-section">
                    {filteredSuspiciousInfo?.map((company, index) => {
                        return (
                            <div key={index} className="flex align-items-center member-response-list justify-content-between">
                                <div className='left'>
                                    <ul className='flex list-none align-items-center gap-2'>
                                        <li className='flex align-items-center gap-2'>
                                        <ImageDisplay profileImage={company?.groupImage} type="group"/> <span>{company.respondingGroup}</span></li>
                                        <li>|</li>
                                        <li>{dateTimeFormat(company.createdAt)}</li>
                                    </ul>
                                </div>
                                <div className='right flex align-items-center'>
                                    <span className='suspicious'>Offered and Suspicious</span> <i className="pi pi-chevron-right" onClick={() => gotoAlertDetailsResponses(selectedRowData?.alertID,company?.responseID )}></i>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </PR.Dialog>)}
            {/* Conversation Dialog */}
            {chatLoading && !isChatPopupOpen?(
                <div className="loading chat-loading">
                    <i className="pi pi-spin pi-spinner"></i>
                </div>
            ):(<PR.Dialog visible={chatVisible}
                footer={chatFooterContent}
                onHide={() => {
                    setChatVisible(false)
                    setErrorMessage('')
                    setChatPopupOpen(false)
                    setChatInfo({
                        alertID:'',
                        alertName:'',
                        groupID:'',
                        conversationID:'',
                        legacyID: ''
                    })
                    formik.resetForm();
                }} 
                className="logout-dialog response-dialog chat-dialog"
                closeOnEscape="true" 
                blockScroll="true">
                <div className="dialog-header">
                    <div className='flex align-items-center gap-2'>
                        <ImageDisplay profileImage={sessionData?.groupImage} type='group'/>
                        <div className='chat-header'>
                            <div className=''>Chat about Alert ID: <span className="capitalize">{chatInfo.legacyID}</span></div>
                            <span className='alert-name'>{chatInfo.alertName}</span>
                        </div>
                    </div>
                </div>
                <div className='pl-4 pr-4 pb-2'>
                    <div>
                    {
                    chatData?.length === 0
                    ? <p className='no-message-p text-center'>No messages yet.</p>
                    : (
                    <div className="dialog-body-section">
                            {chatData.map((item, index) => {
                                const rendermessage = getChatUserName(item.senderID) !== userName?(
                                    <div className='chat-block-received' key={index}>
                                        <div className=' flex align-items-start'>
                                            <div className='mr-3'>
                                                <ImageDisplay profileImage={getChatUserImage(item.senderID)} type="user"/>
                                            </div>
                                            <div className='message-box'>
                                                <div className='message'>
                                                {item?.content && <div className="content mb-2" dangerouslySetInnerHTML={{ __html: item?.content }} />}
                                                {item?.attachment && (() => {
                                                    const attachment = JSON.parse(item.attachment); 
                                                    return (
                                                        <PR.Button
                                                            key={attachment.key}
                                                            icon="pi pi-download"
                                                            label={attachment.name}
                                                            className="file-download-button"
                                                            onClick={() => fetchFileDetails(attachment.key, attachment.name)}
                                                        />
                                                    );
                                                })()}
                                                </div>
                                                <div className='time'>
                                                    <span>{dateTimeFormat(item.createdAt)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>):(
                                    <div className='chat-block-sent flex1 align-items-start' key={index}>
                                        <div className='message-box'>
                                            <div className='message'>
                                                {item?.content && <div className="content mb-2" dangerouslySetInnerHTML={{ __html: item?.content }} />}
                                                {item?.attachment && (() => {
                                                    const attachment = JSON.parse(item.attachment); 
                                                    return (
                                                        <PR.Button
                                                            key={attachment.key}
                                                            icon="pi pi-download"
                                                            label={attachment.name}
                                                            className="file-download-button"
                                                            onClick={() => fetchFileDetails(attachment.key, attachment.name)}
                                                        />
                                                    );
                                                })()}
                                            </div>
                                            <div className='time text-right'>
                                                <span>{dateTimeFormat(item.createdAt)}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                                return <div key={index}>{rendermessage}</div>
                            })}
                            <div ref={messagesEndRef} />
                    </div>
                    )}
                    </div>
                </div>
            </PR.Dialog>)}
            <AlertsSearchFilter 
                filterDialogVisible={filterDialogVisible}
                setFilterDialogVisible={setFilterDialogVisible}
                setFilters={setFilters}
                alertsActiveIndex={activeIndex}
                selectedFilters={selectedFilters}
            />
        </>
    );
};
export const AlertsSearchFilter = ({alertsActiveIndex, filterDialogVisible,setFilterDialogVisible,setFilters,selectedFilters}) => {
    const [rawFilterData] = useState(RAW_FILTER_DATA);
    return (
        <>
            {/* Filter Modal Dialog */}
            <PR.Dialog visible={filterDialogVisible} onHide={() => setFilterDialogVisible(false)} className="response-dialog filter-dialog" closeOnEscape="true" blockScroll="true">
                <SearchFilter
                    setFilterDialogVisible={setFilterDialogVisible}
                    setFilters={setFilters}
                    alertsActiveIndex={alertsActiveIndex}
                    staticOptionData={rawFilterData}
                    selectedFilters={selectedFilters}
                />
            </PR.Dialog>
        </>
    )
}
export default AlertsGrid;
