import axios from "axios";
import { authActions } from "../store/auth";
import { getQueryString } from '../utils/reuse';
import store from "../store";
export const mockBaseUrl = "http://localhost:8000/";

const baseUrl = "/api/v1/";

const errorHandler = (err, dispatch) => {
  let errorMessage = {};
  switch (err.response && err.response.status) {
      case 401:
          errorMessage.severity = 401;
          errorMessage.summary = err.response.statusText;
          errorMessage.errorMsg = err.response.data.errorMsg;
          dispatch(authActions.onLogout());
          break;
      default:
          errorMessage.severity = 'error';
          errorMessage.summary = err.response?.statusText ? err.response.statusText : "Error";
          errorMessage.errorMsg = err.response?.data.error ? err.response.data.error : "Something went wrong, please try again";
          break;
  }
  return errorMessage;
}

export const login = (data, callback) => {
  const { email, password, remember } = data;
  const loginData = { email, password };
  axios.post(baseUrl + "login", loginData)
    .then((response) => {
      if (response) {
        response.data.password = password;
        response.data.remember = remember;
        callback({ result: "SUCCESS", data: response.data });
      } else {
        callback({ result: "FAILED", data: "Failed" });
      }
    })
    .catch((error) => {
      callback({ result: "FAILED", data: error?.response?.data?.error });
    });
};

export const loginOtp = (data, callback) => {
  const otpData = data;
  axios.post(baseUrl + "login/otp", otpData)
    .then((response) => {
      const sessionId = response.headers["sessionid"];
      if (response && sessionId) {
        response.data.sessionId = sessionId;
        callback({ result: "SUCCESS", data: response.data });
      } else {
        callback({ result: "FAILED", data: "Failed" });
      }
    })
    .catch((error) => {
      callback({ result: "FAILED", data: error?.response?.data?.error });
    });
};

export const resendOtp = (data, callback) => {
  axios.post(baseUrl + "resend/otp", data)
    .then((response) => {
        callback({ result: "SUCCESS", data: response.data });
    })
    .catch((error) => {
      callback({ result: "FAILED", data: error?.response?.data?.error });
    });
};

export const createAlert = (data, headers, dispatch, callback) => {
  axios.post(baseUrl + 'alert', data, {headers : headers})
      .then(response => {
          callback({ result: 'SUCCESS', data: response.data});
      }).catch(error => {
          const errorMessage = errorHandler(error,dispatch);
          callback({ result: 'FAILED', error: errorMessage });
      })
};

//All Alerts API
export const setSearchOptions = (obj, data, headers, dispatch, callback) => {
    const queryString = getQueryString(obj);
    axios.post(baseUrl + 'alerts?'+ queryString, data, {headers : headers})
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data});
        }).catch(error => {
            const errorMessage = errorHandler(error,dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}

//Export Alerts
export const exportAlerts = (data, headers, dispatch, callback) => {
  axios.post(baseUrl + 'alerts/export', data, {headers : headers})
      .then(response => {
          callback({ result: 'SUCCESS', data: response.data});
      }).catch(error => {
          const errorMessage = errorHandler(error,dispatch);
          callback({ result: 'FAILED', error: errorMessage });
      })
}

// Alert Details API
export const getAlertDetails = (id, headers, dispatch, callback) => {
  const apiUrl = (typeof(id) === 'string' && id?.toLowerCase()?.includes('alert-'))
    ? `${baseUrl}alert/${id?.toLowerCase()}`
    : `${baseUrl}alert/${Number(id)}`;
  axios.get(apiUrl, {headers: headers})
    .then(response => {
      callback({ result: 'SUCCESS', data: response.data });
    }).catch(error => {
      const errorMessage = errorHandler(error, dispatch);
      callback({ result: 'FAILED', error: errorMessage });
    })
}

// Alert Get Conversation API
export const getAlertConversation = ( id, groupID, headers, dispatch, callback) => {
  axios.get(baseUrl + 'conversation/alert/'+ id+'/group/'+ groupID, {headers : headers})
      .then(response => {
          callback({ result: 'SUCCESS', data: response.data});
      }).catch(error => {
          const errorMessage = errorHandler(error,dispatch);
          callback({ result: 'FAILED', error: errorMessage });
      })
}

//
export const updateAlertConversation = ( id, content, headers, dispatch, callback) => {
  axios.put(baseUrl + 'conversation/'+ id, content,{headers : headers})
      .then(response => {
          callback({ result: 'SUCCESS', data: response?.data});
      }).catch(error => {
          const errorMessage = errorHandler(error,dispatch);
          callback({ result: 'FAILED', error: errorMessage });
      })
}
// this is to update the readData of a conversation
export const updateAlertLasttRead = ( id, request, headers, dispatch, callback) => {
    axios.put(baseUrl + 'conversation/'+ id, request, {headers : headers})
        .then(response => {
            callback({ result: 'SUCCESS', data: response?.data});
        }).catch(error => {
            const errorMessage = errorHandler(error,dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
  }
  
//
export const createAlertConversation = ( id, groupID, content, headers, dispatch, callback) => {
  axios.put(baseUrl + 'conversation/alert/'+ id+'/group/'+ groupID, content, {headers : headers})
      .then(response => {
          callback({ result: 'SUCCESS', data: response?.data});
      }).catch(error => {
          const errorMessage = errorHandler(error,dispatch);
          callback({ result: 'FAILED', error: errorMessage });
      })
}
// Alert Get Sports
export const getSports = (activeIndex = null, headers, dispatch, callback) => {
  axios.get(baseUrl + 'sports' + ((activeIndex === 0 || activeIndex === 1) ? '?all=true' : ''), {headers : headers})
      .then(response => {
          callback({ result: 'SUCCESS', data: response.data});
      }).catch(error => {
          const errorMessage = errorHandler(error,dispatch);
          callback({ result: 'FAILED', error: errorMessage });
      })
}

// Alert Get Player/ Team Names
export const getPlayers = (params = '', headers, dispatch, callback) => {
    // Convert params object to query string
    const queryString = new URLSearchParams(params).toString();

    axios.get(`${baseUrl}tags?${queryString}`, {headers: headers})
      .then(response => {
          callback({ result: 'SUCCESS', data: response.data});
      }).catch(error => {
          const errorMessage = errorHandler(error,dispatch);
          callback({ result: 'FAILED', error: errorMessage });
      })
}

// Create/Edit Tag
export const modifyTag = (request, headers, dispatch, callback) => {
    axios.post(baseUrl + 'tag', request , { headers: headers })
    .then(response => {
        callback({ result: 'SUCCESS', data: response.data });
    }).catch(error => {
        const errorMessage = errorHandler(error, dispatch);
        callback({ result: 'FAILED', error: errorMessage });
    })
}

// Delete Tag
export const deleteAlertTag = (id, headers, dispatch, callback) => {
  axios.delete(baseUrl + `tag/${id}`, { headers: headers })
  .then(response => {
      callback({ result: 'SUCCESS', data: response.data });
  }).catch(error => {
      const errorMessage = errorHandler(error, dispatch);
      callback({ result: 'FAILED', error: errorMessage });
  })
}

// Company tab memebrs list
export const getMembers = (headers, dispatch, callback) => {
  axios.get(baseUrl + 'members', {headers : headers})
      .then(response => {
          callback({ result: 'SUCCESS', data: response.data});
      }).catch(error => {
          const errorMessage = errorHandler(error,dispatch);
          callback({ result: 'FAILED', error: errorMessage });
      })
}

// Get Tournament API
export const getTournament = ( obj, headers, dispatch, callback) => {
  const queryString = getQueryString(obj);
  axios.get(baseUrl + 'tournaments?'+ queryString, {headers : headers})
      .then(response => {
          callback({ result: 'SUCCESS', data: response.data});
      }).catch(error => {
          const errorMessage = errorHandler(error,dispatch);
          callback({ result: 'FAILED', error: errorMessage });
      })
}

// Alert Get Locations
export const getLocations = (headers, dispatch, callback) => {
  axios.get(baseUrl + 'locations', {headers : headers})
      .then(response => {
          callback({ result: 'SUCCESS', data: response.data});
      }).catch(error => {
          const errorMessage = errorHandler(error,dispatch);
          callback({ result: 'FAILED', error: errorMessage });
      })
}

//logOut 
export const logout = (headers, dispatch, callback) => {
  axios.post(baseUrl + 'logout', {}, { headers: headers })
    .then(response => {
      callback({ result: 'SUCCESS', data: response.data });
      dispatch(authActions.onLogout());
    })
    .catch(error => {
      const errorMessage = errorHandler(error, dispatch);
      callback({ result: 'FAILED', error: errorMessage });
    });
};

// Alert Responses API
export const getAlertResponses = ( id, headers, dispatch, callback) => {
  axios.get(baseUrl + 'alert/'+ id + '/responses', {headers : headers})
      .then(response => {
          callback({ result: 'SUCCESS', data: response.data});
      }).catch(error => {
          const errorMessage = errorHandler(error,dispatch);
          callback({ result: 'FAILED', error: errorMessage });
      })
}

// File Details API
export const getFileDetails = (key, headers, dispatch, callback) => {
    axios.get(baseUrl + 'file/' + key, { headers: headers, responseType: 'blob' })       
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data});
            }).catch(error => {
        }).catch(error => {
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
  }
  
//Edit alert description API
export const updateAlertDescription = (key, content, headers, dispatch, callback) => {
    axios.patch(baseUrl + `alert/` + key + `/description`, { content }, {headers : headers})
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data});
        }).catch(error => {
            const errorMessage = errorHandler(error,dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
  };

// Add comment
export const addComment = (key, payload, headers, dispatch, callback) => {
  axios
    .put(`${baseUrl}alert/${key}/comment`, payload, { headers })
    .then((response) => callback({ result: 'SUCCESS', data: response.data }))
    .catch((error) => callback({ result: 'FAILED', error: errorHandler(error, dispatch) }));
};

// Update comment
export const updateComment = addComment; // Reuse the same function since the API and logic are identical.

// Delete comment
  export const deleteComment = (alertId, commentId, headers, dispatch, callback) => {
    axios.delete(baseUrl + `alert/` + alertId + `/comment/` + commentId, {headers : headers})
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data});
        }).catch(error => {
            const errorMessage = errorHandler(error,dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
  };  
// Alert Get Tournament Level
  export const getTournamentLevels = (activeIndex = null, headers, dispatch, callback) => {
    axios.get(baseUrl + 'tournamentlevels' + ((activeIndex === 0 || activeIndex === 1) ? '?all=true' : ''), {headers : headers})
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data});
        }).catch(error => {
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
  }

//Api to create Response  
export const createResponse = (key, data, headers, dispatch, callback) => {
    axios.post(baseUrl + 'alert/' + key + '/response', data, {headers : headers})
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data});
        }).catch(error => {
            const errorMessage = errorHandler(error,dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}

//get report list

export const getReports = (params, headers, dispatch, callback) => {
    const queryString = new URLSearchParams(params).toString();
    axios.get(baseUrl + 'report?' + queryString, { headers })
      .then(response => {
        callback({ result: 'SUCCESS', data: response.data });
      })
      .catch(error => {
        const errorMessage = errorHandler(error, dispatch);
        callback({ result: 'FAILED', error: errorMessage });
      });
};
export const deleteReport = (id, headers, dispatch, callback) => {
  axios.delete(baseUrl + `report?id=${id}`, { headers: headers })
  .then(response => {
      callback({ result: 'SUCCESS', data: response.data });
  }).catch(error => {
      const errorMessage = errorHandler(error, dispatch);
      callback({ result: 'FAILED', error: errorMessage });
  })
}
  // api for groups 
  export const getRegulators = (roleId, headers, dispatch, callback) => {
    axios.get(`${baseUrl}groups?role=${roleId}`, { headers })
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        });
}

// Api for groups
export const getGroups = (roleId, headers, dispatch, callback) => {
    axios.get(`${baseUrl}groups?role=${roleId}`, { headers })
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        });
}

// create report
export const createReport = (data, headers, dispatch, callback) => {
    axios.post(baseUrl + 'report', data, { headers })
      .then(response => {
          callback({ result: 'SUCCESS', data: response.data });
      }).catch(error => {
          const errorMessage = errorHandler(error, dispatch);
          callback({ result: 'FAILED', error: errorMessage });
      });
  }
// get report by id
export const getReportById = (reportID, headers, dispatch, callback) => {
    axios.get(`${baseUrl}report?id=${reportID}`, { headers })
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data });
        })
        .catch(error => {
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        });
};

  //news categories
  
  export const createNewsCategory = (data, headers, dispatch, callback) => {
    axios.post(baseUrl + 'newscategory', data, { headers })
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        });
}

//Get Alerts count API
export const getAlertsCount = (obj, headers, dispatch, callback) => {
    const queryString = getQueryString(obj);
    axios.get(baseUrl + 'gauge?'+ queryString, {headers : headers})
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data});
        }).catch(error => {
            const errorMessage = errorHandler(error,dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}
// get news category
export const getNewsCategories = (headers, dispatch, callback, includeCount = false, includeDescription = false) => {
    let url = baseUrl + 'newscategory';
    const params = [];
    if (includeCount) params.push('includeCount=true');
    if (includeDescription) params.push('includeDescription=true');
    if (params.length) url += '?' + params.join('&');

    axios.get(url, { headers })
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data });
        })
        .catch(error => {
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        });
}

//api for combined group 
export const getGroupsbyRole = (roleId1,roleId2, headers, dispatch, callback) => {
    axios.get(`${baseUrl}groups?role=${roleId1},${roleId2}`, { headers })
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        });
}

//create news

export const createNews = (data, headers, dispatch, callback) => {
    axios.post(baseUrl + 'news', data, { headers })
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        });
};


//delete categories
export const deleteNewsCategory = (id, headers, dispatch, callback) => {
    axios.delete(`${baseUrl}newscategory/${id}`, { headers })
      .then(response => {
        callback({ result: 'SUCCESS', data: response.data });
      }).catch(error => {
        const errorMessage = errorHandler(error, dispatch);
        callback({ result: 'FAILED', error: errorMessage });
      });
};

//edit categories
export const updateNewsCategory = (data, headers, dispatch, callback) => {
    axios.post(`${baseUrl}newscategory`, data, { headers })
      .then(response => {
        callback({ result: 'SUCCESS', data: response.data });
      }).catch(error => {
        const errorMessage = errorHandler(error, dispatch);
        callback({ result: 'FAILED', error: errorMessage });
      });
};
   

// get news list
export const getNews = (obj, headers, dispatch, callback) => {
    const queryString = Object.keys(obj)
        .filter(key => obj[key] !== undefined)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
        .join('&');
    axios.get(baseUrl + 'news?' + queryString, { headers:headers })
      .then(response => {
        callback({ result: 'SUCCESS', data: response.data });
      })
      .catch(error => {
        const errorMessage = errorHandler(error, dispatch);
        callback({ result: 'FAILED', error: errorMessage });
      });
  };

//get news details
export const getNewsDetails = (id, headers, dispatch, callback) => {
    axios.get(`${baseUrl}news?id=${id}`, { headers })
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        });
};

//delete news
export const deleteNews = (id, headers, dispatch, callback) => {
    axios.delete(`${baseUrl}news/${id}`, { headers })
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        });
};

//get new category
export const getNewsByCategory = (categoryId, obj, headers, dispatch, callback) => {
    const queryString = Object.keys(obj)?.filter(key => obj[key] !== undefined)?.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))?.join('&');
    axios.get(`${baseUrl}news?categoryID=${categoryId}&${queryString}`, { headers })
      .then(response => {
        callback({ result: 'SUCCESS', data: response.data });
      })
      .catch(error => {
        const errorMessage = errorHandler(error, dispatch);
        callback({ result: 'FAILED', error: errorMessage });
      });
  };
  
//edit report 
export const editReport = (data, headers, dispatch, callback) => {
    axios.post(baseUrl + 'report', data, { headers })
      .then(response => {
          callback({ result: 'SUCCESS', data: response.data });
      }).catch(error => {
          const errorMessage = errorHandler(error, dispatch);
          callback({ result: 'FAILED', error: errorMessage });
      });
};  
//Get Alerts count API
export const getAlertsByLocation = (obj, headers, dispatch, callback) => {
    const queryString = getQueryString(obj);
    axios.get(baseUrl + 'alerts?'+ queryString, {headers : headers})
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data});
        }).catch(error => {
            const errorMessage = errorHandler(error,dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}

//Get Groups Name for Members
export const getGroupsNames = (headers, dispatch, callback) => {
    axios.get(baseUrl + 'groups?role=1,2', {headers : headers})
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data});
        }).catch(error => {
            const errorMessage = errorHandler(error,dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}
// mark as featured news
export const toggleFeaturedNews = (newsId, obj, headers, dispatch, callback) => {
    const queryString = getQueryString(obj);
    axios.patch(`${baseUrl}news/${newsId}?${queryString}`, {}, { headers })
      .then(response => {
        callback({ result: 'SUCCESS', data: response.data });
      })
      .catch(error => {
        const errorMessage = errorHandler(error, dispatch);
        callback({ result: 'FAILED', error: errorMessage });
      });
  };
  
// update alert status
export const updateAlertStatus = (alertId, formData, headers, dispatch, callback) => {
   
    axios.put(`${baseUrl}alert/${alertId}/status`, formData, { headers })
      .then(response => {
        if (typeof callback === 'function') {
          callback({ result: 'SUCCESS', data: response.data });
        }
      })
      .catch(error => {
        const errorMessage = errorHandler(error, dispatch);
        if (typeof callback === 'function') {
          callback({ result: 'FAILED', error: errorMessage });
        }
      });
  };

  
// getCaseStatus function
export const getCaseStatus = (searchParams, headers, dispatch, callback) => {
    axios
      .get(`${baseUrl}casestatus?${searchParams}`, { headers })
      .then((response) => {
        callback({ result: "SUCCESS", data: response.data });
      })
      .catch((error) => {
        const errorMessage = errorHandler(error, dispatch);
        callback({ result: "FAILED", error: errorMessage });
      });
  };
  
// create group
export const createGroup = (formData, headers, dispatch, callback) => {
    axios.post(baseUrl + 'group', formData, { headers })
      .then(response => {
        callback({ result: 'SUCCESS', data: response.data });
      })
      .catch(error => {
        const errorMessage = errorHandler(error, dispatch);
        callback({ result: 'FAILED', error: errorMessage });
      });
};
  
//get groups
export const getuserManagementGroups = (params, headers, dispatch, callback) => {
    const queryString = new URLSearchParams(params).toString();
    axios.get(`${baseUrl}groupdetails?${queryString}`, { headers })
      .then(response => {
        callback({ result: 'SUCCESS', data: response.data });
      })
      .catch(error => {
        const errorMessage = errorHandler(error, dispatch);
        callback({ result: 'FAILED', error: errorMessage });
      });
  };

export const getuserManagementGroupsnewAPI = (params, headers) => axios
  .get(`${baseUrl}groupdetails?${params}`, { headers })
  .then(r => ({ result: 'SUCCESS', data: r.data }))
  .catch(error => ({ result: 'FAILED', error: errorHandler(error, store.dispatch) }));

export const getSportsnewAPI = headers => axios
  .get(baseUrl + 'sports', { headers })
  .then(response => ({ result: 'SUCCESS', data: response.data }))
  .catch(error => ({ result: 'FAILED', error: errorHandler(error, store.dispatch) }));

export const getLocationsnewAPI = headers => axios
  .get(baseUrl + 'locations', { headers })
  .then(response => ({ result: 'SUCCESS', data: response.data }))
  .catch(error => ({ result: 'FAILED', error: errorHandler(error, store.dispatch) }))

export const getGroupByIdnewAPI = (query, headers) => axios
  .get(`${baseUrl}groupdetails?${query}`, { headers })
  .then(response => ({ result: 'SUCCESS', data: response.data }))
  .catch(error => ({ result: 'FAILED', error: errorHandler(error, store.dispatch) }));

export const getGroupsnewAPI = (roleId, headers) => axios
  .get(`${baseUrl}groups?role=${roleId}`, { headers })
  .then(response => ({ result: 'SUCCESS', data: response.data }))
  .catch(error => ({ result: 'FAILED', error: errorHandler(error, store.dispatch) }));

export const getTournamentLevelsnewAPI = (headers) => axios
  .get(baseUrl + 'tournamentlevels', { headers: headers })
  .then(response => ({ result: 'SUCCESS', data: response.data }))
  .catch(error => ({ result: 'FAILED', error: errorHandler(error, store.dispatch) }))
 
  export const createAndUpateGroupnewAPI = (formData, headers) => axios.post(baseUrl + 'group', formData, { headers })
      .then(response => ({ result: 'SUCCESS', data: response.data }))
      .catch(error => ({ result: 'FAILED', error: errorHandler(error, store.dispatch) }));

  
// create profile
export const createProfile = (groupId, formData, headers, dispatch, callback) => {
    axios.post(baseUrl + `group/${groupId}/user`, formData, { headers })
      .then(response => {
        callback({ result: 'SUCCESS', data: response.data });
      })
      .catch(error => {
        const errorMessage = errorHandler(error, dispatch);
        callback({ result: 'FAILED', error: errorMessage });
      });
    };

//get groups by id
export const getGroupById = (params, headers, dispatch, callback) => {
  const queryString = new URLSearchParams(params).toString();
  axios.get(`${baseUrl}groupdetails?${queryString}`, { headers })
    .then(response => {
      callback({ result: 'SUCCESS', data: response.data });
    })
    .catch(error => {
      const errorMessage = errorHandler(error, dispatch);
      callback({ result: 'FAILED', error: errorMessage });
    });
};

export const updateGroup = (formData, headers, dispatch, callback) => {
  axios.post(baseUrl + 'group', formData, { headers })
    .then(response => {
      callback({ result: 'SUCCESS', data: response.data });
    })
    .catch(error => {
      const errorMessage = errorHandler(error, dispatch);
      callback({ result: 'FAILED', error: errorMessage });
    });
};

  //get groups
  export const getGroupsbyId = (id, headers, dispatch, callback) => {
    
    axios.get(`${baseUrl}groupdetails?id=${id}&users=true`, { headers })
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data });
        })
        .catch(error => {
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        });
};

export const getGroupsbymanagedId= (id, headers, dispatch, callback) => {

    axios.get(`${baseUrl}groupdetails?id=${id}&users=true`, { headers })
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data });
        })
        .catch(error => {
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        });
};
export const updateUserProfiles = (groupId, data, headers, dispatch, callback) => {
  axios.post(`${baseUrl}group/${groupId}/users`, data, { headers })
      .then(response => {
          callback({ result: 'SUCCESS', data: response.data });
      })
      .catch(error => {
          const errorMessage = errorHandler(error, dispatch);
          callback({ result: 'FAILED', error: errorMessage });
      });
};

// update profile
export const updateProfile = (formData, headers, dispatch, callback) => {
  axios.post(baseUrl + 'user', formData, { headers })
    .then(response => {
      callback({ result: 'SUCCESS', data: response.data });
    })
    .catch(error => {
      const errorMessage = errorHandler(error, dispatch);
      callback({ result: 'FAILED', error: errorMessage });
    });
}
export const updateGroupPermissions = (groupId, enabledPermissions, headers, dispatch, callback) => {
  const params = enabledPermissions.join(',');

  axios.post(`${baseUrl}group/${groupId}/permission`, null, {
      params: { pid: params },
      headers,
  })
  .then(response => {
      callback({ result: 'SUCCESS', data: response.data });
  })
  .catch(error => {
      const errorMessage = errorHandler(error, dispatch);
      callback({ result: 'FAILED', error: errorMessage });
  });
};

export const updateAlertAdvice = (id, formData, headers, dispatch, callback) => {
  axios.put(`${baseUrl}alert/${id}/advice`, formData, { headers })
    .then(response => {
      callback({ result: 'SUCCESS', data: response.data });
    })
    .catch(error => {
      const errorMessage = errorHandler(error, dispatch);
      callback({ result: 'FAILED', error: errorMessage });
    });
};

//  API for groups
export const getGroupsWithPermission = (roleId, permission, headers, dispatch, callback) => {
  axios.get(`${baseUrl}groups?role=${roleId}&permission=${permission}`, { headers })
      .then(response => {
          callback({ result: 'SUCCESS', data: response.data });
      }).catch(error => {
          const errorMessage = errorHandler(error, dispatch);
          callback({ result: 'FAILED', error: errorMessage });
      });
};

export const resetpasswordApi = (data, expiryErrorMsg, dispatch) =>  axios.post(`${baseUrl}resetpassword`, data)
    .then(r => ({ result: 'SUCCESS', data: r.data }))
    .catch(e => ({ result: 'FAILED', error: e?.response?.status === 400? {severity:'error', summary:'Error', errorMsg: expiryErrorMsg} : errorHandler(e, dispatch) }));

export const forgotpasswordApi = (data, dispatch) => axios.post(`${baseUrl}forgotpassword`, data)
  .then(r => ({ result: 'SUCCESS', data: r.data }))
  .catch(e => ({ result: 'FAILED', error: e?.response?.status === 400? {severity:'error', summary:'Error', errorMsg:'There is still an active reset link available for use. Please check your email.'} : errorHandler(e, dispatch) }));

//  API for assignee names
export const getAssigneeList = (groupID, headers, dispatch, callback) => {
  axios.get(`${baseUrl}groupdetails?id=${groupID}&users=true`, { headers })
      .then(response => {
          callback({ result: 'SUCCESS', data: response.data });
      }).catch(error => {
          const errorMessage = errorHandler(error, dispatch);
          callback({ result: 'FAILED', error: errorMessage });
      });
};

//  API to update assignee
export const updateAssignee = (obj, headers, dispatch, callback) => {
  axios.put(`${baseUrl}alert/${obj.alertID}/assignee/${obj.assigneeID}`, {}, { headers })
      .then(response => {
          callback({ result: 'SUCCESS', data: response.data });
      }).catch(error => {
          const errorMessage = errorHandler(error, dispatch);
          callback({ result: 'FAILED', error: errorMessage });
      });
};


export const addCaseUpdateNotenewAPI = (id, data, headers) => axios.put(`${baseUrl}alert/${id}/notes`,data, {headers})
.then(response => ({ result: 'SUCCESS', data: response.data }))
.catch(error => ({ result: 'FAILED', error: errorHandler(error,store.dispatch) }));