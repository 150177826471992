import moment from "moment";
import sportIcons from '../assets/images/sports'
import * as PR from "../prime-react/index";
import { eventIcon } from "../assets/images/index";
import { newsTypes, RAW_FILTER_DATA } from "../shared/common";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const toastDisplayTime = 3000;
export const queryLimit = 50;
export const rowsPerPage = [20, 50, 100]
export const maxNumberOfFiles = +process.env.REACT_APP_MAX_NUMBER_OF_FILES
export const pagecount = +process.env.REACT_APP_PAGE_COUNT;
export const chatCount = +process.env.REACT_APP_CHAT_COUNT;
export const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL
export const resendOtpTime = +process.env.REACT_APP_RESEND_OTP_TIME;
export const descriptionCount = +process.env.REACT_APP_DESCRIPTION_COUNT;
export const displayStatesForSelectedCountries = ["United States of America", "Canada"]
export const privacyPolicyLink = '/policies/IBIA_Privacy_Notice.pdf';
export const termsAndConditionsLink = '/policies/IBIA_Code_of_Conduct.pdf'
export const aletFormForValidation = [
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];;
export const fileTypesConfig = [
  "application/zip",
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
export const chatFileTypesConfig = [
  'application/pdf', 
  'text/csv', 
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 
  'application/msword', 
  'application/vnd.ms-excel', 
  'image/png', 
  'image/jpeg'
];
export const newsFileTypesConfig = [
  "application/pdf",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "image/jpeg",
  "image/png",
  "image/gif"
];
export const reportsFileTypesConfig =  [
  "application/pdf",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
];
export const fileUploadLimit = {
  label: process.env.REACT_APP_MAX_FILE_SIZE_LABEL,
  value: +process.env.REACT_APP_MAX_FILE_SIZE_VALUE,
}
export const formatNumber = (number) => {
  return number < 10 ? `0${number}` : number;
};

export const getQueryString = (obj) => {
  const queryString = Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join("&");
  return queryString;
};

export const formatDate = (dateString) => {
  const currentDate = new Date(dateString);

  // Extract components
  const optionsDate = {
    month: "long",
    day: "2-digit",
    year: "numeric",
  };
  const optionsTime = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    timeZone: "UTC",
  };

  const formattedDate = currentDate.toLocaleDateString("en-US", optionsDate);
  const formattedTime = currentDate.toLocaleTimeString("en-US", optionsTime);

  const finalDate = {
    displayDate: formattedDate,
    displayTime: `${formattedTime} UTC`,
  };

  return finalDate;
};

export const formatTime = (date) => {
  const currentDate = new Date(date);
  const options = { hour: "numeric", minute: "2-digit", hour12: true };
  return <strong style={{fontWeight:"inherit"}} title={moment(date).utc().format("MMMM DD, YYYY HH:mm [UTC]")} >{currentDate
    .toLocaleTimeString("en-US", options)
    .replace(/\bAM\b/, "am")
    .replace(/\bPM\b/, "pm")}</strong>
};

export const DateFormat = (dateString) => {
  const options = { day: "2-digit", month: "short", year: "numeric" };
  return <strong style={{fontWeight:"inherit"}} title={moment(dateString).utc().format("MMMM DD, YYYY HH:mm [UTC]")} >{new Date(dateString)
    .toLocaleDateString("en-GB", options)
    .replace(/ /g, " ")}</strong>
};

export const dateTimeFormat = payload => <div title={moment(payload).utc().format("MMMM DD, YYYY HH:mm [UTC]")}>{DateFormat(payload)}, {formatTime(payload)}</div>
export const DateFormatComma = (dateString) => {
  const options = { day: "2-digit", month: "short", year: "numeric" };
  return <strong style={{fontWeight:"inherit"}} title={moment(dateString).utc().format("MMMM DD, YYYY HH:mm [UTC]")} >{new Date(dateString)
    .toLocaleDateString("en-GB", options)
    .replace(/ /g, " ")},</strong>
};
export const dateTimeFormatComma = payload => <div title={moment(payload).utc().format("MMMM DD, YYYY HH:mm [UTC]")}>{DateFormatComma(payload)} {formatTime(payload)}</div>

export const formatShortDate = (dateString) => {
  const date = moment(dateString);

  const formattedDate = date.format("DD MMM YYYY");

  return formattedDate;
};

export const formatDateRange = (dateString) => {
  if (dateString === "" || dateString === undefined) {
    return undefined;
  }
  const date = moment(dateString);

  // Format the date
  const formattedDate = date.format("YYYY-MM-DD");

  return formattedDate;
};

export const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const getRoleId = (role) => {
  switch (role) {
    case "member":
      return [1,2];
    case "analyst":
      return [1,2];;
    case "regulator":
      return 3;
    case "governingbody":
      return 4;
    case "managedmember":
      return [1,2];
    default:
      return null;
  }
};

export const categoryColors = {
  "League Updates ": "red-color",
  "Regulatory Changes ": "green-color",
  "Industry Insights ": "blue-color",
  "Event Previews ": "purple-color",
  "Policy Reminders ": "yellow-color",
  "Educational Resources ": "green-color",
};
export const typeFilter = [
  { id: "1", name: "suspicious betting" },
  { id: "2", name: "summary" },
  { id: "3", name: "others" },
];

export const newsTitleChar = 95;

export const truncateText = (text, maxLength) => {
  if (text?.length > maxLength) {
    return text?.slice(0, maxLength) + "...";
  }
  return text;
};

export const capitalizeFirstLetterOfEachSentence = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const getSportIcon = (sport) => {
  if (sportIcons[sport]) {
    const SportIcon = sportIcons[sport]
    return (SportIcon)
  } else {
    return (<PR.Image src={eventIcon} alt="Sport" />)
  }
}

export const getNewsTypeLabel = (newsType) => {
  return newsTypes?.filter(type => type.id === newsType)[0]?.name;
}

export const getStatusLabel = (status) => {
  return RAW_FILTER_DATA.caseStatus?.filter(type => type.id === status)[0]?.name;
}

export const sortAlerts = (alerts) => {
  return alerts?.sort((a, b) => {
    // Define sorting priorities
    const priority = (alert) => [
      (alert.alertStatus === "open" && alert?.responseRequired) ? 0 : 1, // 0 if responseRequired, 1 otherwise
      (alert.alertStatus === "evaluating" && alert?.responseRequired) ? 0 : 1,
      (alert?.activeConversation) ? 0 : 1, // 0 if activeConversation, 1 otherwise
      alert?.alertStatus === "open" ? 0 : (alert?.alertStatus === "evaluating" ? 1 : 2), // 0 if "open", 1 if "evaluating", 2 otherwise
      alert?.caseStatus === 'under investigation' ?-1:1,
      alert?.caseStatus === 'sanctioned' ?-1:1,
      alert?.caseStatus === 'closed' ?-1:1,
      alert?.alertStatus === 'not reported' ?-1:1
    ];

    // Compare based on the defined priorities
    const aPriority = priority(a);
    const bPriority = priority(b);

    return aPriority
    ?.map((val, index) => val - bPriority[index])
    ?.find(diff => diff !== 0) || 0; // find the first non-zero difference or return 0 if all are equal
  });
};

export const optionsforBarGraphs = {
  plugins: { 
      legend: false,
  },
  scales: {
      x: {
          grid: {
              display: false
          },
          ticks: {
            autoSkip: false,   // Show all labels, regardless of their length
            maxRotation: 60,   // Rotate labels to 60 degrees
            minRotation: 60,    // Keep consistent rotation angle
            callback: function(value) {
              const label = this.getLabelForValue(value);
              return label.length > 20 
                  ? label.slice(0, 20) +'...'
                  : label;
          }
        }
      },
      y: {
          grid: {
              color: '#D1DCD3',
          },
          border: {
              dash: [5, 6],
          },
          ticks: {
            stepSize: 1
          }
      }
  }
};
export const optionsforBarGraphsDetails = {
  ...optionsforBarGraphs,
  maintainAspectRatio: false,
  scales: {
    ...optionsforBarGraphs.scales,
    x: {
      ...optionsforBarGraphs.scales.x,
      ticks: {
        font: {
          style: 'normal',
        },
      },
    },
  },
};

export const optionsforBarGraphsWithPercentage = {
  plugins: { 
      legend: false,
  },
  scales: {
      x: {
          grid: {
              display: false
          },
          ticks: {
            autoSkip: false,   // Show all labels, regardless of their length
            maxRotation: 60,   // Rotate labels to 60 degrees
            minRotation: 60,    // Keep consistent rotation angle
            callback: function(value) {
              const label = this.getLabelForValue(value);
              return label.length > 20 
                  ? label.slice(0, 20) +'...'
                  : label;
          }
        }
          
      },
      y: {
          grid: {
              color: '#D1DCD3',
          },
          border: {
              dash: [5, 6],
          },
          ticks: {
              stepSize: 20,
              callback: function(value) {
                  return `${value} %`;
              }
          }
      }
  }
};

export const optionsforBarGraphsWithPercentageDetails = {
  ...optionsforBarGraphsWithPercentage,
  maintainAspectRatio: false, // Adding this property here
  scales: {
    ...optionsforBarGraphsWithPercentage.scales,
    x: {
      ...optionsforBarGraphs.scales.x,
      ticks: {
        font: {
          style: 'normal',
        },
      },
    },
  },
};


export const optionsforResponseType = {
  plugins: { legend: false },
  indexAxis: 'y',
  aspectRatio: 0.8,
  responsive: true,
  maintainAspectRatio: false,
  scales: {
      x: {
          stacked: true,
          ticks: { display: false },
          grid: { display: false },
          border: { display: false }
      },
      y: {
          stacked: true,
          ticks: { display: false },
          grid: { display: false },
          border: { display: false }
      }
  }
};
export const textTypingHandler = (e, setValue, limit) => {
  let value = e.target.value;
  if (value.length <= limit) {
    setValue(value);
  }
};

export const handlePaste = (e, value, setValue, limit) => {
  e.preventDefault();
  const pasteText = e.clipboardData.getData('text');
  const textarea = e.target;
  const cursorPosition = textarea.selectionStart;
  const end = textarea.selectionEnd;
  const newText = value.slice(0, cursorPosition) + pasteText + value.slice(end);
  
  if (newText.length <= limit) {
    setValue(newText);
  } else {
    // Limit the text length to the specified limit
    setValue(newText.substring(0, limit));
  }

  // Move the cursor to the end of the pasted text
  setTimeout(() => {
    textarea.setSelectionRange(cursorPosition + pasteText.length, cursorPosition + pasteText.length);
  }, 0);
};

export const  formatResponseTypeLabel = {
  "suspicious": "Suspicious",
  "notoffered": "Not Offered",
  "notsuspicious": "Not Suspicious"
}
// Function to captilize the first letter 
export const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const remainingCharacters = (chatCount, fieldValue) => {
  const usedCharacters = getPlainText(fieldValue) || 0;
  const remainingCount = Math.max(chatCount - usedCharacters, 0);
  return `Remaining Characters: ${remainingCount}`;
};

export const permission ={
news: 3,
reports:4

}

export const roleIds ={
  regulator: 3,
  governingbody:4
  
  }

// Function to remove null values and empty arrays
export const cleanData = (data) => {
  if (Array.isArray(data)) {
    const cleanedArray = data
      .map(cleanData)
      .filter(item => 
        item !== null && 
        item !== undefined && 
        !(typeof item === 'object' && !Array.isArray(item) && Object.keys(item).length === 0) && // Remove empty objects
        !(Array.isArray(item) && item.length === 0) // Remove empty arrays
      );

    // Return null for empty arrays
    return cleanedArray.length > 0 ? cleanedArray : null;
  }

  if (typeof data === 'object' && data !== null) {
    const cleanedObject = Object.entries(data)
      .reduce((acc, [key, value]) => {
        const cleanedValue = cleanData(value);
        if (cleanedValue !== null && cleanedValue !== undefined && cleanedValue !== '') {
          acc[key] = cleanedValue;
        }
        return acc;
      }, {});

    // Return null for empty objects
    return Object.keys(cleanedObject).length > 0 ? cleanedObject : null;
  }

  // Remove null, undefined, and empty strings
  return data !== null && data !== undefined && data !== '' ? data : null;
};

export const handlePolicyDownload = (event, url, filename) => {
  event.preventDefault();
  const link = document.createElement('a');
  link.href = url; // The path to the PDF
  link.download = filename; // Set the filename for download
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link); // Clean up
};

export const sortByCreatedAt = (data) => {
  if (!data || data.length === 0) {
    return [];
  }

  return data.sort((a, b) => {
    const dateDifference = new Date(b.createdAt) - new Date(a.createdAt);
    
    if (dateDifference !== 0) {
      return dateDifference; // Sort by createdAt first
    }

    return 0;
  });
}
// Function to extract only required key values
export const extractRequiredKeys = (array, keys) => {
  return array.map(obj => {
    let newObj = {};
    keys.forEach(key => {
      if (obj.hasOwnProperty(key)) {
        newObj[key] = obj[key];
      }
    });
    return newObj;
  });
};

export const exportModifyDateTime = (dateVal) => {
  const year = new Date(dateVal).getFullYear();
  return (year !== 1) ? moment(dateVal).format('DD-MM-YYYY HH:MM:SS') : '-';
}

// Function to calculate the UTC offset in hours and minutes for a given timezone
export const calculateUTCOffset = (timeZone) => {
  const now = new Date();
  const timeZoneOffset = new Date(now.toLocaleString("en-US", { timeZone }));

  // Get the difference in milliseconds and convert to minutes
  const offsetInMinutes = (timeZoneOffset.getTime() - now.getTime()) / (1000 * 60);

  // Calculate hours and minutes
  let hours = Math.floor(Math.abs(offsetInMinutes) / 60);
  let minutes = Math.round(Math.abs(offsetInMinutes) % 60);

  // If minutes are 60 or more, carry over to hours
  if (minutes === 60) {
    hours += 1;
    minutes = 0;
  }

  const sign = offsetInMinutes >= 0 ? "+" : "-";

  // Return formatted offset, padding hours and minutes
  return `UTC${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};

  // Function to format time zones with UTC/GMT offsets and short names
  export const getTimeZoneOptions = () => {
    const timeZoneList = Intl.supportedValuesOf('timeZone');
    
    // Add UTC+00:00 manually to the list
    const timeZonesWithUTC = timeZoneList.map((tz) => {
        const utcOffset = calculateUTCOffset(tz);
        const label = `${utcOffset} (${tz})`;
        return { label, value: tz };
    });

    return timeZonesWithUTC;
};

export const pdfDownload = (content, headerText, fileName) => {
  const canvas = html2canvas(content, { scale: 2 });
  const pdf = new jsPDF({
    orientation: "landscape",
    unit: "mm",
    format: "a4",
  });

  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();
  const margin = 5;
  const headerHeight = 20;
  const contentStartPosition = headerHeight + 10;
  const pageContentHeight = pdfHeight - contentStartPosition - margin; // Height available for content on each page

  let imgHeight = (canvas.height * pdfWidth) / canvas.width; // Scale the image height to fit PDF width
  let position = 0; // Initial position to start rendering content

  pdf.setFontSize(10);
  pdf.text(headerText, margin, headerHeight); // Add header text on the first page

  while (position < imgHeight) {
    // Calculate the height of the remaining content to render
    const remainingHeight = imgHeight - position;
    const renderHeight = Math.min(pageContentHeight, remainingHeight); // Render only content that fits on the current page

    // Create a portion of the canvas for the current page's content
    const canvasPart = document.createElement('canvas');
    const context = canvasPart.getContext('2d');
    canvasPart.width = canvas.width;
    canvasPart.height = Math.min(remainingHeight, pageContentHeight) * (canvas.width / pdfWidth); // Scale canvas part height

    // Draw the current portion of the original canvas
    context.drawImage(
      canvas,
      0,
      position * (canvas.width / pdfWidth), // Start Y position on the original canvas
      canvas.width,
      renderHeight * (canvas.width / pdfWidth), // Height to draw from the original canvas
      0,
      0,
      canvasPart.width,
      canvasPart.height
    );

    const partImgData = canvasPart.toDataURL("image/png");

    pdf.addImage(partImgData, "PNG", margin, contentStartPosition, pdfWidth - 2 * margin, renderHeight);

    position += renderHeight; // Move position to the next page

    if (position < imgHeight) {
      pdf.addPage(); // Add new page if there's more content
      pdf.setFontSize(10); // Reset the font size for the new page
      pdf.text(headerText, margin, headerHeight); // Add header on each new page
    }
  }

  pdf.save(`${fileName}.pdf`);
}

const renderTextEditorHeader = () => {
  return (
    <span className="ql-formats">
      <button className="ql-bold" aria-label="Bold"></button>
      <button className="ql-italic" aria-label="Italic"></button>
      <button className="ql-list" value="ordered"></button>
      <button className="ql-list" value="bullet"></button>
    </span>
  );
};

export const textEditorHeader = renderTextEditorHeader();

export const getPlainText = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  return doc.body.textContent !== 'null' &&  doc.body.textContent ? doc.body.textContent?.trim().length : 0;
};
export const getValidRouteName = (routename, type = '') => {
  if (routename?.includes("conversation/alert")) {
    const match = routename.match(/conversation\/alert\/([A-Za-z0-9-]+)\/group\/(\d+)/);
    if(match?.length >= 2){
      const alertId = match[1]; // "1"
      const groupId = match[2]; // "3"
      const url = `/alert-details/${alertId}?group=${groupId}`;
      return url
    }
  }
  return !type ? routename : false;

}
export const handleNumericInput = (e) => {
  const { value } = e.target;
  const numericValue = value.replace(/[^0-9]/g, ''); // Only allow numeric characters
  e.target.value = numericValue;
};
export const exportFile = async (divId, exportType, downloadedFilename) => {
  const divElement = document.getElementById(divId);

  if (!divElement) {
    console.error("Div not found");
    return;
  }

  try {
    const canvas = await html2canvas(divElement, {
      scale: 2,
      backgroundColor: null, // Ensure transparency
    });
    // Convert the canvas to a base64 image
    const base64Image = canvas.toDataURL("image/png");

    if (exportType === "png") {
      const link = document.createElement("a");
      link.href = base64Image;
      link.download = `${downloadedFilename}.png`;
      link.click(); // Trigger download
    } else if (exportType === "pdf") {
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      // Calculate PDF dimensions and scale the image
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const ratio = Math.min(pdfWidth / canvasWidth, pdfHeight / canvasHeight);
      const imgWidth = canvasWidth * ratio;
      const imgHeight = canvasHeight * ratio;

      const topMargin = 10;
      pdf.addImage(base64Image, "PNG", 0, topMargin, imgWidth, imgHeight);

      pdf.save(`${downloadedFilename}.pdf`);
    } else {
      console.error("Invalid export type. Use 'png' or 'pdf'.");
    }
  } catch (error) {
    console.error("Error exporting:", error);
  }
};
