import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import * as PR from "../../../prime-react/index";
import Layout from '../../../layouts/Layout';
import { Avatar } from "../../../assets/images/index";
import { getNews, deleteNews } from "../../../services/api";
import "./NewsAndUpdates.scss";
import NewsItem from '../../../shared/News/NewsItem';
import CreateNewsDialog from './NewsDialog/CreateNewsDialog';
import { pagecount, queryLimit, sortByCreatedAt } from '../../../utils/reuse';
import { useToast } from '../../../context/ToastContext';
import moment from 'moment';

const NewsAndUpdates = () => {
    const { showToast } = useToast();
    const [newsData, setNewsData] = useState([]);
    const [paginatedNewsData, setPaginatedNewsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [createNewsDialogVisible, setCreateNewsDialogVisible] = useState(false);

    const newsTabIndex = useSelector(state => state.news.activeIndex)
    const [selectedTab, setSelectedTab] = useState(newsTabIndex); 
    const [currentPage, setCurrentPage] = useState(1);
    const [deletedNewsIds, setDeletedNewsIds] = useState([]);
    const pageCount = pagecount;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const sessionData = useSelector(state => state.auth.sessionData);
    const headers = useMemo(() => ({ sessionid: sessionData.sessionId }), [sessionData.sessionId]);
    const [selectedNews, setSelectedNews] = useState({})
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [dates, setDates] = useState(null);
    const [error, setError] = useState("");
    const searchedNews = useRef('');
    const trigger = useRef(null);
    const errorRef = useRef(null);

    const getTabId = (newsType) => {
        switch (newsType) {
            case "generalnews":
                return 0;
            case "integritypreviews":
                return 1;
            case "sportingsanctions":
                return 2;
            default:
                return 0;
        }
    }
    const fetchNews = useCallback((page = 1, query = '', dateRange = null) => {
        setLoading(true);
        const obj = {
            offset: (page - 1) * pageCount,
            limit: pageCount + 1,
            type: selectedTab === 0 ? '1' : selectedTab === 2 ? '2' : '3',
        };
        if (query) {
            obj.search = query;
        }
        if (dateRange && dateRange?.length > 0) {
            const startDate= moment(dateRange[0]).format('YYYY-MM-DD');
            const endDate= dateRange[1] ? moment(dateRange[1]).format('YYYY-MM-DD') : startDate;
            obj.startDate = startDate;
            obj.endDate = endDate;
        }
        getNews(obj, headers, dispatch, (response) => {
            if (response.result === 'SUCCESS') {
                const responseData = response.data?.length ? sortByCreatedAt(response.data) : [];
                const data = responseData?.slice(0, pageCount);
                setNewsData(responseData);
                setPaginatedNewsData(data)
            } else {
                const error = response.error;
                showToast(error.severity, error?.errorMsg || error?.summary);
            }
            setLoading(false);
        });
    }, [pageCount, selectedTab, headers, dispatch, showToast]);
    useEffect(() => {
        fetchNews(1);
    }, [fetchNews]);


    const handleDeleteNews = (id, newsType) => {
        setSelectedNews({id:id, newsType:newsType})
        setShowDeleteDialog(true)
    }

    const handleDelete = () => {
        setSelectedTab(getTabId(selectedNews.newsType))
        setLoading(true);
        deleteNews(selectedNews.id, headers, dispatch, (response) => {
            if (response.result === 'SUCCESS') {
                fetchNews(currentPage);
                setDeletedNewsIds([...deletedNewsIds, selectedNews.id]);
                showToast("success", "News deleted successfully");
            } else {
                const error = response.error;
                showToast(error.severity, error?.errorMsg || error?.summary);
            }
            setSelectedNews({})
            setShowDeleteDialog(false);
            setLoading(false);
        });
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchNews(page, searchQuery, dates)
    };

    const handleSelectCategory = (category) => {
        setCreateNewsDialogVisible(false);
        if (category) {
            navigate("/create-news", { state: { category } });
        }
    };

    const handleCNewsCategoriesClick = () => {
        navigate("/news-categories");
    };

    const clearError = () => {
        errorRef.current = null;
        setError(null);
    };
    
    const setTemporaryError = (message) => {
        errorRef.current = message;
        setError(message);
        setTimeout(() => {
            if (errorRef.current === message) {
                clearError();
            }
        }, 2500);
    };
    
    const searchHandler = (e) => {
        setCurrentPage(1);
        const query = e.target.value;
        setSearchQuery(query);
        if (query.length < 3) {
            if (searchedNews.current !== '') {
                searchedNews.current = '';
                if (trigger.current) clearTimeout(trigger.current);
                trigger.current = setTimeout(() => fetchNews(1, '', dates), 1000);
            }
            setTemporaryError('Please enter at least 3 characters');
            return;
        }
    
        if (query.length > queryLimit) {
            setTemporaryError(`Search can be at most ${queryLimit} characters long`);
            return;
        }
    
        if (searchedNews.current === query) {
            return; // Prevent redundant refetch
        }
    
        searchedNews.current = query;
        if (trigger.current) clearTimeout(trigger.current);
        trigger.current = setTimeout(() => {
            fetchNews(1, query, dates)
        }, 1000);
    };
    const dateRangeHandler = (e) => {
        setDates(e.value);
        setCurrentPage(1);
        if(e.value && !e.value?.includes(null)){
            fetchNews(1, searchQuery, e.value)
        } else if(e.value === null) {
            fetchNews(1, searchQuery, e.value)
        }
       
    }
    const displayNewsGrid = (tab) => {
        return (
            <div className="news-tab general-news-tab">
            
                <div className="filter-section flex align-items-start gap-2">
                    <div className="card">
                        <div className="flex align-items-center news-search-bar p-input-icon-right">
                            <span className="p-input-icon-left w-12">
                                <i className="pi pi-search" />
                                <PR.InputText
                                    placeholder="Search.."
                                    className="search-box news-search w-12"
                                    value={searchQuery}
                                    onChange={searchHandler}
                                />
                            </span>
                            <span className="right-clear-icon">
                             {!!searchQuery && <i style={{cursor:'pointer'}} onClick={()=>searchHandler({target: {value: ""}})} className="pi pi-times text-sm mr-2" />}
                            </span>
                        </div>
                        {error && <p className="error-message mt-2">{error}</p>}
                    </div>
                    <div className="card custom-clear-calendar">
                        <PR.Calendar
                            value={dates}
                            onChange={dateRangeHandler}
                            iconPos="left"
                            readOnlyInput
                            showIcon
                            selectionMode="range"
                            className="news-date-calendar news-calendar"
                            placeholder="Select Date"
                            showButtonBar
                            dateFormat="dd/mm/yy"
                            maxDate={new Date()}
                        />
                        {dates && (
                            <div className='custom-clear'>
                                <i className="pi pi-times text-sm"onClick={() => dateRangeHandler({ value: null })}></i>
                            </div>
                        )}
                    </div>
                    <PR.Button label="Create News" icon="pi pi-plus" className="create-button" onClick={() => setCreateNewsDialogVisible(true)} />
                    {tab === 'generalnews' &&
                        <PR.Button label="News Categories" icon="pi pi-sign-in" onClick={handleCNewsCategoriesClick} iconPos='right' className="info-button categories-button" />
                    }
                </div>
                <div className='new-list-section'>
                    <div className='list-wrapper'>
                        {loading ? ( <div className="loading new-updates-loading"> <i className="pi pi-spin pi-spinner"></i> </div> ) 
                        : ( (paginatedNewsData?.length > 0) ? (paginatedNewsData?.map((news, index) => (
                                    <React.Fragment key={news.id}>
                                        <NewsItem
                                            news={news}
                                            Avatar={Avatar}
                                            isMember={false}
                                            newsType={tab}
                                            handleDeleteNews={handleDeleteNews}
                                            fetchNews={fetchNews}
                                            deletedNewsIds={deletedNewsIds}
                                        />
                                        <div className="hr-line my-4"></div>
                                    </React.Fragment>
                                )))
                                : (<p>No news created yet</p>)
                        )}
                    </div>
                    {/* Pagination Section */}
                    {newsData?.length > 0 && (
                        <div className="pagination">
                            <div className="grid grid-nogutter align-items-center justify-content-between">
                                <span>Page {currentPage}</span>
                                <ul className="flex list-none align-items-center gap-3">
                                    <li className={currentPage === 1 ? "disabled" : ""} onClick={() => handlePageChange(currentPage - 1)} >
                                        Previous
                                    </li>
                                    <li className={ newsData?.length <= pageCount ? "disabled" : "" } onClick={() => handlePageChange(currentPage + 1)} >
                                        Next
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    return (
        <>
              <Layout backBtn={"home"} backBtnLabel="Home">
                <section className="all-alerts-section all-tags-section mt-5">
                    <div className="m-0">
                        <h1>News & Updates</h1> 
                    </div>
                    <div className="alerts-tab news-updates-section">
                        <PR.TabView activeIndex={selectedTab} onTabChange={(e) => {
                            setSelectedTab(e.index);
                            setSearchQuery('');
                            setCurrentPage(1);
                            setDates(null)
                            }}>
                            <PR.TabPanel header="General news">
                                {displayNewsGrid('generalnews')}
                            </PR.TabPanel>
                            <PR.TabPanel header="Integrity previews">
                                {displayNewsGrid('integritypreviews')}
                            </PR.TabPanel>
                            <PR.TabPanel header="Sporting sanctions">
                                {displayNewsGrid('sportingsanctions')}
                            </PR.TabPanel>
                        </PR.TabView>
                    </div>
                </section>
            </Layout>
            {/*  News delete dialog */}
            <PR.Dialog visible={showDeleteDialog} className="news-dialog" header="Delete news" modal closable={false}
                    footer={
                        <>
                            <PR.Button
                                label="Cancel"
                                className="p-button-text custom-cancel-button"
                                onClick={() => setShowDeleteDialog(false)}
                            />
                            <PR.Button
                                label="Delete"
                                className="p-button-danger custom-delete-button"
                                onClick={handleDelete}
                            />
                        </>
                    }
                >
                    <div className="confirmation-content">
                        <span>Are you sure you want to delete this news?</span>
                    </div>
            </PR.Dialog>
            {/*  Create news dialog */}
            <CreateNewsDialog visible={createNewsDialogVisible} onHide={() => setCreateNewsDialogVisible(false)} onSelectCategory={handleSelectCategory} />
        </>
    );
};

export default NewsAndUpdates;
